.success__screen {
  width: 100%;
  height: 100%;
  background-color: #e5e5e5;
  overflow-y: auto;
}

.success__view {
  width: 100%;
  font-family: "Montserrat-Bold";
  display: flex;
  align-items: center;
  justify-content: center;
}

.success__container {
  max-width: 400px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  position: relative;
  display: block;
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 12px;
}

.success__container li p {
  max-width: 261px;
}

.success__container__text-long {
  max-width: 300px !important;
}

@media (max-width: 500px) {
  .success__view {
    height: 100%;
  }
  .success__container {
    max-width: none;
    height: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.success__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 22px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e4e6e8;
}

.success__header svg {
  fill: var(--color-secondary);
}

.success__header h3 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: var(--color-text-dark);
}

.success__content-top {
  list-style-type: none; /* Remove bullets */
  padding: 18px; /* Remove padding */
  margin: 0; /* Remove margins */
  border-bottom: 1px solid #e4e6e8;
}
.success__content-top li {
  display: flex;
  align-items: center;
}
.success__content-top li span {
  flex-grow: 0;
  margin-right: 30px;
}
.success__content-top li span svg {
  fill: var(--color-success-icons);
}
.success__content-top li p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding: 0px;
  margin: 0px;
  margin-top: 14px;
  margin-bottom: 14px;
}
.success__content-top button {
  background-color: var(--color-primary);
  color: white;
  margin-top: 26px;
  margin-bottom: 14px;
  border: none;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
}

.success__content-bottom {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e4e6e8;
  padding-bottom: 55px;
}
.success__content-bottom img {
  width: 110px;
  margin-bottom: 8px;
}
.success__content-bottom p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin: 0px;
  margin-top: 16px;
  margin-bottom: 12px;
}
.success__content-bottom a {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: var(--color-primary);
}

.success__content-bottom img.climate {
  width: 160px;
}

.success__content-bottom.climate {
  border-bottom: none;
  margin-top: 24px;
}

.success__footer {
  margin-top: 20px;
  margin-bottom: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(64, 64, 64, 0.5);
  cursor: pointer;
}

.success__footer img {
  height: 14px;
  margin-left: 6px;
}
