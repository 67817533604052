@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Roboto:wght@400;500;700&display=swap);
html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "Montserrat-Bold";
  src: url(/static/media/Montserrat-Bold.079ca05d.ttf);
}

@font-face {
  font-family: "Montserrat";
  src: url(/static/media/Montserrat-Regular.3cd78665.ttf);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --color-text-dark: #1d2934;
  --color-text-gray: #666d74;
}

.block {
  display: block;
}

.flex {
  display: -webkit-flex;
  display: flex;
}

.flex-column {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.hidden {
  display: none;
}
.sign-up__screen {
  width: 100%;
  height: 100%;
  background-color: #e5e5e5;
  overflow-y: auto;
}

.sign-up {
  width: 100%;
  font-family: "Montserrat-Bold";
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.sign-up__container {
  max-width: 400px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  position: relative;
  display: block;
  margin-top: 60px;
  margin-bottom: 60px;
}

@media (max-width: 500px) {
  .sign-up {
    height: 100%;
  }
  .sign-up__container {
    max-width: none;
    height: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 0px;
    overflow-y: auto;
  }
}

.sign-up__title {
  text-align: center;
  margin-top: 28px;
  font-weight: 500;
  font-size: 15px;
  color: var(--color-primary);
}

.sign-up__loading-logo {
  width: 20px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.sign-up__logo {
  width: 140px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.sign-up__logo.climate {
  width: 200px;
  margin-top: 20px;
}

.sign-up__separator {
  margin-top: 22px;
  color: #e4e6e8;
  width: 348px;
  opacity: 0.2;
}

.sign-up__content-title {
  font-weight: 400;
  font-size: 18px;
  color: var(--color-text-dark);
  text-align: center;
  margin-top: 54px;
  margin-bottom: 0px;
}

.sign-up__form {
  width: 302px;
  margin-top: 0px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.sign-up__label {
  display: block;
  font-weight: 300;
  font-size: 13px;
  padding-top: 16px;
  color: var(--color-text-dark);
}

.first {
  padding-top: 30px;
}

.sign-up__input {
  box-sizing: border-box;
  width: 100%;
  background-color: #eeeeee;
  border: none;
  border-radius: 8px;
  padding: 8px 14px 8px 14px;
  margin-top: 6px;
}

.sign-up__input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.sign-up__button {
  width: 100%;
  margin-top: 24px;
  padding: 8px 14px 8px 14px;
  border-radius: 8px;
  border: none;
  background-color: var(--color-primary);
  color: white;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

.sign-up__button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.sign-up__button.loading {
  cursor: progress !important;
}

.sign-up__bottom {
  text-align: center;
  width: 100%;
  margin: 50px 0px 30px 0px;
}

.sign-up__bottom-title {
  font-weight: normal;
  font-size: 12px;
  color: rgba(64, 64, 64, 0.5);
  cursor: pointer;
}

.sign-up__bottom-logo {
  display: block;
  width: 26%;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.success__screen {
  width: 100%;
  height: 100%;
  background-color: #e5e5e5;
  overflow-y: auto;
}

.success__view {
  width: 100%;
  font-family: "Montserrat-Bold";
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.success__container {
  max-width: 400px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  position: relative;
  display: block;
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 12px;
}

.success__container li p {
  max-width: 261px;
}

.success__container__text-long {
  max-width: 300px !important;
}

@media (max-width: 500px) {
  .success__view {
    height: 100%;
  }
  .success__container {
    max-width: none;
    height: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.success__header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;

  margin-top: 22px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e4e6e8;
}

.success__header svg {
  fill: var(--color-secondary);
}

.success__header h3 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: var(--color-text-dark);
}

.success__content-top {
  list-style-type: none; /* Remove bullets */
  padding: 18px; /* Remove padding */
  margin: 0; /* Remove margins */
  border-bottom: 1px solid #e4e6e8;
}
.success__content-top li {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.success__content-top li span {
  -webkit-flex-grow: 0;
          flex-grow: 0;
  margin-right: 30px;
}
.success__content-top li span svg {
  fill: var(--color-success-icons);
}
.success__content-top li p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding: 0px;
  margin: 0px;
  margin-top: 14px;
  margin-bottom: 14px;
}
.success__content-top button {
  background-color: var(--color-primary);
  color: white;
  margin-top: 26px;
  margin-bottom: 14px;
  border: none;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
}

.success__content-bottom {
  margin-top: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 1px solid #e4e6e8;
  padding-bottom: 55px;
}
.success__content-bottom img {
  width: 110px;
  margin-bottom: 8px;
}
.success__content-bottom p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin: 0px;
  margin-top: 16px;
  margin-bottom: 12px;
}
.success__content-bottom a {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: var(--color-primary);
}

.success__content-bottom img.climate {
  width: 160px;
}

.success__content-bottom.climate {
  border-bottom: none;
  margin-top: 24px;
}

.success__footer {
  margin-top: 20px;
  margin-bottom: 26px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(64, 64, 64, 0.5);
  cursor: pointer;
}

.success__footer img {
  height: 14px;
  margin-left: 6px;
}

.dropdown-button {
    height: -webkit-max-content;
    height: max-content;
}

.field-form__input-group .dropdown-button input,
.field-form__input-group .dropdown-button span {
    cursor: pointer;
}

.dropdown-button span {
    position: absolute;
    margin-top: 18px;
    margin-left: -25px;
    width: 24px;
    height: 24px;
    fill: #666D74;
}

.dropdown-content {
    position: absolute;
    z-index: 9999;
    overflow-y: auto;
    max-height: 210px;
    width: 100%;
    border: 1px solid #E6E7E8;
    border-radius: 8px;
    background-color: white;
}

.dropdown-content-option {
    padding: 10px 14px;
}

.dropdown-content-option:hover {
    background-color: #F2F3F3;
    cursor: pointer;
}
.field-form {
  box-sizing: border-box;
  position: relative;
  height: 100%;
}
.field-form__header {
  font-family: Montserrat;
  margin: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: var(--color-text-dark);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 30px;
}
.field-form__header.climate {
  margin-bottom: 20px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e4e6e8;
}
.field-form__header-left {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: top;
          align-items: top;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: var(--color-text-dark);
}
.field-form__header-left span {
  width: 20px !important;
  margin-right: 16px;
}
.field-form__header span {
  width: 22px;
  color: #666d74;
  cursor: pointer;
}
.field-form__header span:hover {
  color: red;
}

.field-form__input-group {
  margin-top: 22px;
}
.field-form__input-group label {
  display: block;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: var(--color-text-dark);
}
.field-form__input-group-input {
  border: none;
  padding: 2px 12px 10px 0px;
  margin-top: 10px;
  box-sizing: border-box;
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--color-text-dark);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.field-form__input-group-input span {
  margin-left: 8px;
}

.field-form__input-group input  {
  padding: 10px 12px 10px 12px;
  margin-top: 10px;
  box-sizing: border-box;
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  cursor: text;
  color: #1A232F;
  background-color: white;
  border: 1px solid #C4C4C4;
  border-radius: 8px;
}
.field-form__input-group input::-webkit-input-placeholder {
  color: var(--color-text-gray);
  opacity: 1;
}
.field-form__input-group input:-ms-input-placeholder {
  color: var(--color-text-gray);
  opacity: 1;
}
.field-form__input-group input::placeholder {
  color: var(--color-text-gray);
  opacity: 1;
}
.field-form__input-group input:focus {
  outline: 1px solid rgba(0, 116, 181, 0.4);
}
.field-form__input-group input.climate  {
  background-color: transparent;
  border: 1px solid #c4c4c4;
}
.field-form__input-group input:disabled,
.dropdown:disabled {
  background-color: #eeeeee;
  cursor: not-allowed;
}

.field-form__input-group.climate {
  margin-top: 1px;
}

.field-form__labels-container {
  min-height: 150px;
  max-height: -webkit-max-content;
  max-height: max-content;
}

.field-form__labels {
  margin-top: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.field-form__labels span {
  border-radius: var(--border-radius-pills);
  border: 1px solid var(--other-color-pills);
  color: var(--color-pills);
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding: 2px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 6px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: initial;
          flex-grow: initial;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 4px;
  cursor: pointer;
  transition: all 0.1s;
}
.field-form__labels span:hover {
  opacity: 0.7;
}
.field-form__labels span svg {
  margin-left: 6px;
  margin-right: -6px;
  height: 13px;
}

.field-form__selected-crop {
  background-color: var(--other-color-pills);
  font-weight: 500;
  border: 0px !important;
}

.field-form__button {
  margin-top: 20px;
  color: white;
  border-radius: 8px;
  border: none;
  padding: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  background-color: var(--color-primary);
  text-align: center;
  cursor: pointer;
}
@media (max-height: 600px) {
  .field-form__button {
    position: relative;
    margin-top: 33px;
  }
}
@media (max-width: 500px) {
  .field-form__button {
    position: relative;
    margin-top: 33px;
  }
}

.field-form__button:hover {
  opacity: 0.9;
}

.field-form__button:active {
  opacity: 0.8;
}

.field-form__button.disabled {
  background-color: #999999;
  cursor: not-allowed;
}

.field-table {
  box-sizing: border-box;
  
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;

  width: 100%;
  height: 100%;
  max-height: -webkit-max-content;
  max-height: max-content;
}

.field-table__logo {
  width: 80px;
}

.field-table__logo:hover {
  cursor: pointer;
}

.field-table__logo.climate {
  width: 150px;
}
.field-table__logo-container.climate {
  padding-bottom: 20px;
  padding-top: 6px;
  border-bottom: 1px solid #e4e6e8;
}

.field-table__header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 10px;
  margin-right: 20px;
}

.field-table__header-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: var(--color-text-dark);
}

.field-table__header-add-button svg {
  margin-top: 10px;
  margin-right: -5px;
  fill: #666d74;
}
.field-table__header-add-button svg:hover {
  fill: var(--color-primary);
}

.field-table__header-input {
  margin-top: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: var(--color-text-dark);
  height: 30px;
  width: 90%;
  border-radius: 8px;
  border: 1px solid #E6E7E8;
}
.field-table__header-input:hover {
  border: 1px solid #B3B6BA;
}
.field-table__header-input::-webkit-input-placeholder {
  color: #bbbbbb;
}
.field-table__header-input:-ms-input-placeholder {
  color: #bbbbbb;
}
.field-table__header-input::placeholder {
  color: #bbbbbb;
}

.field-table__header-input:focus {
  outline: 2px solid var(--color-primary);
}

.field-table__header-button {
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid var(--color-primary-opacity);
  padding: 6px 14px 6px 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: var(--color-primary);
  background-color: transparent;
  transition: 0.1s;
}

.field-table__header-button:hover {
  color: white;
  background-color: var(--color-primary);
  cursor: pointer;
}

.field-table__header-button:active {
  opacity: 0.8;
}

.field-table__header-button span {
  margin-right: 10px;
  font-size: 18px;
  font-weight: 400;
}

.field-table__search {
  padding: 4px;
  padding-left: 32px;
  padding-right: 30px;
  margin-right: 20px;
  position: relative;

  border: 1px solid #E6E7E8;
  border-radius: 8px;
}
.field-table__search.climate {
  background-color: transparent;
  border: 1px solid #c4c4c4;
}

.field-table__search input {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #1A232F;
  background-color: transparent;
  border-radius: 8px;
  border: none;
  padding: 3px;
  width: 100%;
}

.field-table__search input::-webkit-input-placeholder {
  color: #B3B6BA;
  opacity: 1;
}

.field-table__search input:-ms-input-placeholder {
  color: #B3B6BA;
  opacity: 1;
}

.field-table__search input::placeholder {
  color: #B3B6BA;
  opacity: 1;
}

.field-table__search input:focus {
  outline: 2px solid var(--color-primary);
}

.field-table__search span {
  position: absolute;
  left: 12px;
  top: 6px;
  width: 18px;
  height: 18px;
}

.field-table__bottom-section {
  margin-top: 30px;
  margin-right: 20px;
}

.field-table__payment-button {
  color: white;
  border-radius: 8px;
  border: none;
  padding: 10px;
  margin-top: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  background-color: var(--color-primary);
  text-align: center;
  cursor: pointer;
  transition: 0.1s;
}

.field-table__terms-conds {
  display: -webkit-flex;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #777777;
}
.field-table__terms-conds label {
  cursor: pointer;
}
.field-table__terms-conds label a {
  font-style: italic;
  color: var(--color-primary);
}

@media (max-width: 500px) {
  .field-table__logo {
    display: none;
  }
  .field-table__header {
    margin-top: -24px;
  }
}

.field-table__payment-button:hover {
  opacity: 0.9;
}

.field-table__payment-button:active {
  opacity: 0.8;
}

.field-table__payment-button.disabled {
  background-color: #999999;
}
.field-table__payment-button.loading {
  cursor: progress;
}

.field-table__list {
  padding: 0px;
  padding-right: 20px;
  margin: 0px;
  margin-top: 18px;
  height: 100%;
  max-height: 70%;
  list-style-type: none;
  overflow-y: auto;
}

.field-table__list li {
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  padding-bottom: 14px;
  padding-top: 14px;
  border-bottom: 1px solid #e4e6e8;
  line-height: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  text-align: left;
}

.field-table__list-item.zero-fields {
  display: block;
  text-align: center;
  border-bottom: none;
  cursor: auto;
}

.zero-fields span {
  font-weight: bold;
}

.field-table__list-icon {
  width: 50px;
}

.field-table__list-icon svg {
  fill: var(--color-field-icon);
}

.field-table__list-item {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  cursor: pointer;
  transition: 0.1s;
}

/* .field-table__list-item:hover {
  background-color: #EEEEEE;
} */

.field-table__list-item div {
  width: 100%;
}

.field-table__list-item-name {
  margin: 0px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--color-text-dark);
}
.field-table__list-item-crop {
  margin: 0px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--color-text-dark);
  margin-top: 4px;
}
.field-table__list-item-coords {
  margin: 0px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: rgba(29, 41, 52, 0.3);
  margin-top: 4px;
}

.report-input-error {
  border-color: red;
}

.report-error {
  color: red;
  font-size: 14px;
  margin-top: -3px;
  margin-bottom: 16px;
}

.fields-error {
  color: red;
  margin-top: 4px;
  font-size: 14px;
}

.terms-error {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: red;
  margin-top: 4px;
}

.map-container {
  width: 100%;
  height: 100%;
}

.marker-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #1a232f;
  /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  position: absolute;
  bottom: 12px;
  margin-left: 48px;
  white-space: nowrap;
  text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff,
    1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
}

/*********** MAPBOX MAP OVERRIDES ***************/

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-ctrl-attrib {
  display: none !important;
}

.mapboxgl-popup-content {
  pointer-events: none;
  z-index: 9;
}

.mapboxgl-popup-close-button {
  display: none;
}
.mapboxgl-marker.mapboxgl-marker-anchor-center {
  cursor: pointer;
}

.field-input-layout {
  position: relative;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 100%;
}

.field-input-layout__sidebar {
  -webkit-flex-grow: 0;
          flex-grow: 0;

  padding: 20px;
  padding-right: 0;
  
  max-height: -webkit-max-content;
  
  max-height: max-content;
  width: 268px;
  background-color: white;

  transition: all 0.2s;
}

.field-input-layout__map {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.field-input-layout__mobile-toggle {
  display: none;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #e4e6e8;
  padding-top: 4px;
  background-color: white;
  z-index: 11;
}

.field-input-layout__sidebar.mobile-open {
  height: 100%;
  border-radius: 0px;
}

.field-input-layout__sidebar-content {
  box-sizing: border-box;
  position: relative;

  display: -webkit-flex;

  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;

  padding-right: 20px;
  
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.field-input-layout__sidebar-content.--no-scroll {
  padding-right: 0;
  overflow-y: unset;
}

@media (max-width: 500px) {
  .field-input-layout__sidebar {
    z-index: 10;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    width: 100%;
    height: 30%;
    overflow: hidden;
    border-radius: 10px 10px 0px 0px;
  }

  .field-input-layout__mobile-toggle {
    display: block;
  }

  .field-input-layout__sidebar-content {
    padding-top: 36px;
    box-sizing: border-box;
  }
  .field-input-layout__map {
    height: 72%;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

