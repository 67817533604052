.sign-up__screen {
  width: 100%;
  height: 100%;
  background-color: #e5e5e5;
  overflow-y: auto;
}

.sign-up {
  width: 100%;
  font-family: "Montserrat-Bold";
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign-up__container {
  max-width: 400px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  position: relative;
  display: block;
  margin-top: 60px;
  margin-bottom: 60px;
}

@media (max-width: 500px) {
  .sign-up {
    height: 100%;
  }
  .sign-up__container {
    max-width: none;
    height: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 0px;
    overflow-y: auto;
  }
}

.sign-up__title {
  text-align: center;
  margin-top: 28px;
  font-weight: 500;
  font-size: 15px;
  color: var(--color-primary);
}

.sign-up__loading-logo {
  width: 20px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.sign-up__logo {
  width: 140px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.sign-up__logo.climate {
  width: 200px;
  margin-top: 20px;
}

.sign-up__separator {
  margin-top: 22px;
  color: #e4e6e8;
  width: 348px;
  opacity: 0.2;
}

.sign-up__content-title {
  font-weight: 400;
  font-size: 18px;
  color: var(--color-text-dark);
  text-align: center;
  margin-top: 54px;
  margin-bottom: 0px;
}

.sign-up__form {
  width: 302px;
  margin-top: 0px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.sign-up__label {
  display: block;
  font-weight: 300;
  font-size: 13px;
  padding-top: 16px;
  color: var(--color-text-dark);
}

.first {
  padding-top: 30px;
}

.sign-up__input {
  box-sizing: border-box;
  width: 100%;
  background-color: #eeeeee;
  border: none;
  border-radius: 8px;
  padding: 8px 14px 8px 14px;
  margin-top: 6px;
}

.sign-up__input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.sign-up__button {
  width: 100%;
  margin-top: 24px;
  padding: 8px 14px 8px 14px;
  border-radius: 8px;
  border: none;
  background-color: var(--color-primary);
  color: white;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

.sign-up__button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.sign-up__button.loading {
  cursor: progress !important;
}

.sign-up__bottom {
  text-align: center;
  width: 100%;
  margin: 50px 0px 30px 0px;
}

.sign-up__bottom-title {
  font-weight: normal;
  font-size: 12px;
  color: rgba(64, 64, 64, 0.5);
  cursor: pointer;
}

.sign-up__bottom-logo {
  display: block;
  width: 26%;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}
