.field-table {
  box-sizing: border-box;
  
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  height: 100%;
  max-height: max-content;
}

.field-table__logo {
  width: 80px;
}

.field-table__logo:hover {
  cursor: pointer;
}

.field-table__logo.climate {
  width: 150px;
}
.field-table__logo-container.climate {
  padding-bottom: 20px;
  padding-top: 6px;
  border-bottom: 1px solid #e4e6e8;
}

.field-table__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 20px;
}

.field-table__header-title {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: var(--color-text-dark);
}

.field-table__header-add-button svg {
  margin-top: 10px;
  margin-right: -5px;
  fill: #666d74;
}
.field-table__header-add-button svg:hover {
  fill: var(--color-primary);
}

.field-table__header-input {
  margin-top: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: var(--color-text-dark);
  height: 30px;
  width: 90%;
  border-radius: 8px;
  border: 1px solid #E6E7E8;
}
.field-table__header-input:hover {
  border: 1px solid #B3B6BA;
}
.field-table__header-input::placeholder {
  color: #bbbbbb;
}

.field-table__header-input:focus {
  outline: 2px solid var(--color-primary);
}

.field-table__header-button {
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid var(--color-primary-opacity);
  padding: 6px 14px 6px 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary);
  background-color: transparent;
  transition: 0.1s;
}

.field-table__header-button:hover {
  color: white;
  background-color: var(--color-primary);
  cursor: pointer;
}

.field-table__header-button:active {
  opacity: 0.8;
}

.field-table__header-button span {
  margin-right: 10px;
  font-size: 18px;
  font-weight: 400;
}

.field-table__search {
  padding: 4px;
  padding-left: 32px;
  padding-right: 30px;
  margin-right: 20px;
  position: relative;

  border: 1px solid #E6E7E8;
  border-radius: 8px;
}
.field-table__search.climate {
  background-color: transparent;
  border: 1px solid #c4c4c4;
}

.field-table__search input {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #1A232F;
  background-color: transparent;
  border-radius: 8px;
  border: none;
  padding: 3px;
  width: 100%;
}

.field-table__search input::placeholder {
  color: #B3B6BA;
  opacity: 1;
}

.field-table__search input:focus {
  outline: 2px solid var(--color-primary);
}

.field-table__search span {
  position: absolute;
  left: 12px;
  top: 6px;
  width: 18px;
  height: 18px;
}

.field-table__bottom-section {
  margin-top: 30px;
  margin-right: 20px;
}

.field-table__payment-button {
  color: white;
  border-radius: 8px;
  border: none;
  padding: 10px;
  margin-top: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  background-color: var(--color-primary);
  text-align: center;
  cursor: pointer;
  transition: 0.1s;
}

.field-table__terms-conds {
  display: flex;
  gap: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #777777;
}
.field-table__terms-conds label {
  cursor: pointer;
}
.field-table__terms-conds label a {
  font-style: italic;
  color: var(--color-primary);
}

@media (max-width: 500px) {
  .field-table__logo {
    display: none;
  }
  .field-table__header {
    margin-top: -24px;
  }
}

.field-table__payment-button:hover {
  opacity: 0.9;
}

.field-table__payment-button:active {
  opacity: 0.8;
}

.field-table__payment-button.disabled {
  background-color: #999999;
}
.field-table__payment-button.loading {
  cursor: progress;
}

.field-table__list {
  padding: 0px;
  padding-right: 20px;
  margin: 0px;
  margin-top: 18px;
  height: 100%;
  max-height: 70%;
  list-style-type: none;
  overflow-y: auto;
}

.field-table__list li {
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  padding-bottom: 14px;
  padding-top: 14px;
  border-bottom: 1px solid #e4e6e8;
  line-height: 20px;
  display: flex;
  align-items: flex-start;
  text-align: left;
}

.field-table__list-item.zero-fields {
  display: block;
  text-align: center;
  border-bottom: none;
  cursor: auto;
}

.zero-fields span {
  font-weight: bold;
}

.field-table__list-icon {
  width: 50px;
}

.field-table__list-icon svg {
  fill: var(--color-field-icon);
}

.field-table__list-item {
  flex-grow: 1;
  cursor: pointer;
  transition: 0.1s;
}

/* .field-table__list-item:hover {
  background-color: #EEEEEE;
} */

.field-table__list-item div {
  width: 100%;
}

.field-table__list-item-name {
  margin: 0px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--color-text-dark);
}
.field-table__list-item-crop {
  margin: 0px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--color-text-dark);
  margin-top: 4px;
}
.field-table__list-item-coords {
  margin: 0px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: rgba(29, 41, 52, 0.3);
  margin-top: 4px;
}

.report-input-error {
  border-color: red;
}

.report-error {
  color: red;
  font-size: 14px;
  margin-top: -3px;
  margin-bottom: 16px;
}

.fields-error {
  color: red;
  margin-top: 4px;
  font-size: 14px;
}

.terms-error {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: red;
  margin-top: 4px;
}
