.field-form {
  box-sizing: border-box;
  position: relative;
  height: 100%;
}
.field-form__header {
  font-family: Montserrat;
  margin: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: var(--color-text-dark);
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.field-form__header.climate {
  margin-bottom: 20px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e4e6e8;
}
.field-form__header-left {
  display: flex;
  align-items: top;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: var(--color-text-dark);
}
.field-form__header-left span {
  width: 20px !important;
  margin-right: 16px;
}
.field-form__header span {
  width: 22px;
  color: #666d74;
  cursor: pointer;
}
.field-form__header span:hover {
  color: red;
}

.field-form__input-group {
  margin-top: 22px;
}
.field-form__input-group label {
  display: block;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: var(--color-text-dark);
}
.field-form__input-group-input {
  border: none;
  padding: 2px 12px 10px 0px;
  margin-top: 10px;
  box-sizing: border-box;
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--color-text-dark);
  display: flex;
  align-items: center;
}
.field-form__input-group-input span {
  margin-left: 8px;
}

.field-form__input-group input  {
  padding: 10px 12px 10px 12px;
  margin-top: 10px;
  box-sizing: border-box;
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  cursor: text;
  color: #1A232F;
  background-color: white;
  border: 1px solid #C4C4C4;
  border-radius: 8px;
}
.field-form__input-group input::placeholder {
  color: var(--color-text-gray);
  opacity: 1;
}
.field-form__input-group input:focus {
  outline: 1px solid rgba(0, 116, 181, 0.4);
}
.field-form__input-group input.climate  {
  background-color: transparent;
  border: 1px solid #c4c4c4;
}
.field-form__input-group input:disabled,
.dropdown:disabled {
  background-color: #eeeeee;
  cursor: not-allowed;
}

.field-form__input-group.climate {
  margin-top: 1px;
}

.field-form__labels-container {
  min-height: 150px;
  max-height: max-content;
}

.field-form__labels {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

.field-form__labels span {
  border-radius: var(--border-radius-pills);
  border: 1px solid var(--other-color-pills);
  color: var(--color-pills);
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding: 2px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 6px;
  display: flex;
  flex-grow: initial;
  align-items: center;
  margin-top: 4px;
  cursor: pointer;
  transition: all 0.1s;
}
.field-form__labels span:hover {
  opacity: 0.7;
}
.field-form__labels span svg {
  margin-left: 6px;
  margin-right: -6px;
  height: 13px;
}

.field-form__selected-crop {
  background-color: var(--other-color-pills);
  font-weight: 500;
  border: 0px !important;
}

.field-form__button {
  margin-top: 20px;
  color: white;
  border-radius: 8px;
  border: none;
  padding: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  background-color: var(--color-primary);
  text-align: center;
  cursor: pointer;
}
@media (max-height: 600px) {
  .field-form__button {
    position: relative;
    margin-top: 33px;
  }
}
@media (max-width: 500px) {
  .field-form__button {
    position: relative;
    margin-top: 33px;
  }
}

.field-form__button:hover {
  opacity: 0.9;
}

.field-form__button:active {
  opacity: 0.8;
}

.field-form__button.disabled {
  background-color: #999999;
  cursor: not-allowed;
}
