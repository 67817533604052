.field-input-layout {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

.field-input-layout__sidebar {
  flex-grow: 0;

  padding: 20px;
  padding-right: 0;
  
  max-height: max-content;
  width: 268px;
  background-color: white;

  transition: all 0.2s;
}

.field-input-layout__map {
  flex-grow: 1;
}

.field-input-layout__mobile-toggle {
  display: none;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #e4e6e8;
  padding-top: 4px;
  background-color: white;
  z-index: 11;
}

.field-input-layout__sidebar.mobile-open {
  height: 100%;
  border-radius: 0px;
}

.field-input-layout__sidebar-content {
  box-sizing: border-box;
  position: relative;

  display: flex;
  justify-content: space-between;

  padding-right: 20px;
  
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.field-input-layout__sidebar-content.--no-scroll {
  padding-right: 0;
  overflow-y: unset;
}

@media (max-width: 500px) {
  .field-input-layout__sidebar {
    z-index: 10;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    width: 100%;
    height: 30%;
    overflow: hidden;
    border-radius: 10px 10px 0px 0px;
  }

  .field-input-layout__mobile-toggle {
    display: block;
  }

  .field-input-layout__sidebar-content {
    padding-top: 36px;
    box-sizing: border-box;
  }
  .field-input-layout__map {
    height: 72%;
  }
}
