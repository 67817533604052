.dropdown-button {
    height: max-content;
}

.field-form__input-group .dropdown-button input,
.field-form__input-group .dropdown-button span {
    cursor: pointer;
}

.dropdown-button span {
    position: absolute;
    margin-top: 18px;
    margin-left: -25px;
    width: 24px;
    height: 24px;
    fill: #666D74;
}

.dropdown-content {
    position: absolute;
    z-index: 9999;
    overflow-y: auto;
    max-height: 210px;
    width: 100%;
    border: 1px solid #E6E7E8;
    border-radius: 8px;
    background-color: white;
}

.dropdown-content-option {
    padding: 10px 14px;
}

.dropdown-content-option:hover {
    background-color: #F2F3F3;
    cursor: pointer;
}