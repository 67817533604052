.map-container {
  width: 100%;
  height: 100%;
}

.marker-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #1a232f;
  /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  position: absolute;
  bottom: 12px;
  margin-left: 48px;
  white-space: nowrap;
  text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff,
    1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
}

/*********** MAPBOX MAP OVERRIDES ***************/

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-ctrl-attrib {
  display: none !important;
}

.mapboxgl-popup-content {
  pointer-events: none;
  z-index: 9;
}

.mapboxgl-popup-close-button {
  display: none;
}
.mapboxgl-marker.mapboxgl-marker-anchor-center {
  cursor: pointer;
}
